import React from 'react'

// App components
// import { FreeConsultForm } from '../../components/FreeConsultForm'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import callPhoneNumber from '../../assets/ScheduleFREEConsultation_Button_Call.png'
import scheduleOnline from '../../assets/ScheduleFREEConsultation_Button_Schedule.png'
import '../../styles/schedule-a-free-consult.css'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, metrics, presets } from '../../utils/theme'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '700px',
    minWidth: '300px',
    backgroundColor: 'white',
    [presets.Tablet]: {
      minWidth: '400px',
    },
    flex: 1,
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1 / 2),
    marginBottom: rhythm(1 / 2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    marginTop: metrics.defaultMargin,
    marginBottom: metrics.defaultMargin,
    ...themeStyles.textPadding,
  },
  formattedList: {
    display: 'inline-block',
    textAlign: 'left',
  },
  optionImages: {
    marginRight: 0,
    marginBottom: 0,
    height: '45px',
    minWidth: '100px',
    maxWith: '150px',
    [presets.Mobile]: {
      minWidth: '120px',
    },
    [presets.Desktop]: {
      minWidth: '160px',
    },
  },
  headerText: {
    color: '#00AFD7',
    fontSize: '30px',
  },
}

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} hideForm>
        <div
          id="schedule-a-free-consult-div"
          css={{ ...themeStyles.contentWidth }}
        >
          <PageTitle
            title="Schedule a Free Consult"
            bannerKey={'THE_NEXT_STEP'}
          />
          <div css={styles.container}>
            <div
              css={{
                maxWidth: '750px',
                flex: 0.5,
                flex: 1,
                textAlign: 'center',
                minWidth: '300px',
                [presets.Tablet]: {
                  minWidth: '500px',
                },
                marginBottom: metrics.defaultMargin,
              }}
            >
              <h2 css={styles.headerText}>Schedule Your FREE Consultation</h2>
              <div>
                <p css={{ margin: 0 }}>
                  A confident smile is closer than you think...{' '}
                </p>
                <p css={{ marginTop: '-10px' }}>
                  Request a free braces or invisalign consultation at Collins
                  Orthodontics, and you will receive the following:
                </p>
              </div>
              <div css={{ textAlign: 'center' }}>
                <ul css={styles.formattedList}>
                  <li>Free diagnostic records (photos, x-rays)</li>
                  <li>Free examination and consultation with our doctors</li>
                  <li>Diagnosis and treatment options for your case</li>
                  <li>
                    An estimate for cost and anticipated length of treatment
                  </li>
                  <li>
                    And a free puppy (kidding, but that would be pretty cool)
                  </li>
                </ul>
              </div>
              <div id="option-1-phone-number" css={{ marginBottom: '20px' }}>
                <p css={{ margin: 0 }}>Option 1</p>

                <a href="tel:507-258-5400">
                  <img
                    src={callPhoneNumber}
                    alt={`Call Collins Phone Number`}
                    css={styles.optionImages}
                  />
                </a>
              </div>
              <div id="option-2-schedule-online">
                <p css={{ margin: 0 }}>Option 2</p>
                <a
                  href="https://edgebooking.ortho2.com/search?catalog=44af553b-7237-4dc5-927f-733d63a0412a&code=cosbhj&cellrequired=true"
                  target="blank"
                >
                  <img
                    src={scheduleOnline}
                    alt={`Schedule online with Collins`}
                    css={styles.optionImages}
                  />
                </a>
              </div>
            </div>
            {/*Removing consult form from the page*/}
            {/* <FreeConsultForm /> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
