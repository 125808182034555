import React from 'react'

// App components
import MainHelmet from './MainHelmet'

// App content
import ContactUs from '../assets/banners/SubpageBanners_ContactUs.jpg'
import COVID from '../assets/COVID-19_Guidelines_Banner-min.jpg'
import Insurance from '../assets/banners/SubpageBanners_Insurance.jpg'
import mapAndDirections from '../assets/banners/SubpageBanners_Map&Directions.jpg'
import meetDrCollins from '../assets/banners/SubpageBanners_MeetDrCollins.jpg'
import Orthodontics from '../assets/banners/SubpageBanners_Orthodontics.jpg'
import OurTeam from '../assets/banners/1_StaffPage_Banner.jpg'
import Resources from '../assets/banners/SubpageBanners_Resources.jpg'
import shark from '../assets/banners/SubpageBanners_Adopt-A-Shark.jpg'
import TheNextStep from '../assets/banners/SubpageBanners_TheNextStep.jpg'
// import virtualConsult from '../assets/virtual-header.jpg'
import WhyCollins from '../assets/banners/SubpageBanners_WhyCollins.jpg'
// import referAFriendNintendoSwitch from '../assets/banners/ReferAFriendBannerNintendoSwitch.jpg'
// import ReferAFriendBannerEdSheeran from '../assets/banners/ReferAFriendBannerEdSheeran.jpg'
// import ReferAFriendBanner from '../assets/banners/ReferAFriendVikings.jpg'
// import ReferAFriendBanner from '../assets/banners/morgan_wallen.jpg'
// import ReferAFriendBanner from '../assets/banners/quest.jpg'
import ReferAFriendBanner from '../assets/banners/ReferAFriendTwolvesGame.jpg'

// App utils
import { rhythm } from '../utils/typography'
import { presets } from '../utils/theme'

const bannerMap = {
  // VIRTUAL_CONSULT: virtualConsult,
  CONTACT_US: ContactUs,
  INSURANCE: Insurance,
  ORTHODONTICS: Orthodontics,
  RESOURCES: Resources,
  THE_NEXT_STEP: TheNextStep,
  WHY_COLLINS: WhyCollins,
  OUR_TEAM: OurTeam,
  MAP_AND_DIRECTIONS: mapAndDirections,
  MEET_DR_COLLINS: meetDrCollins,
  SHARK: shark,
  COVID: COVID,
  REFER_A_FRIEND: ReferAFriendBanner,
}

export const styles = {
  title: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: 0,
    margin: 0,
    minHeight: '50px',
    padding: '5px',
    backgroundColor: 'rgba(0,0,0,.5)',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: rhythm(3 / 4),
    letterSpacing: '5px',
    lineHeight: rhythm(1),
    [presets.Mobile]: {
      fontSize: rhythm(1.3),
      letterSpacing: '15px',
      lineHeight: rhythm(1.4),
    },
    fontWeight: 700,
    textAlign: 'center',
  },
}

class PageTitle extends React.Component {
  render() {
    console.log(this.props)

    const container = {
      position: 'relative',
      width: '100%',
      minHeight: '250px',
      backgroundSize: 'cover',
      backgroundPosition: this.props.backgroundPosition || 'left',
      minHeight: this.props.minHeight || '250px',
      [presets.Mobile]: {
        backgroundPosition: this.props.backgroundPosition || 'center',
      },
      ...this.props.containerStyle,
    }

    let titleStyle = { ...styles.title, ...this.props.titleStyle }

    if (this.props.titleBackgroundColor) {
      titleStyle = {
        ...titleStyle,
        backgroundColor: this.props.titleBackgroundColor,
      }
    }

    if (this.props.hidePhoto) {
      return (
        <div>
          <MainHelmet
            description={
              this.props.metaDescription ||
              `Collins Orthodontics, Rochester MN | ${this.props.title}`
            }
            title={
              this.props.metaTitle ||
              `${this.props.title}. Click the link to find out more!`
            }
          />
          <h1
            id="page-title"
            css={[
              titleStyle,
              { position: 'relative', marginBottom: rhythm(1) },
            ]}
          >
            {this.props.title.toUpperCase()}
          </h1>
        </div>
      )
    }

    return (
      <div
        css={[
          container,
          {
            backgroundImage: `url(${
              bannerMap[this.props.bannerKey] || bannerMap.CONTACT_US
            })`,
          },
        ]}
      >
        <MainHelmet
          description={
            this.props.metaDescription ||
            `Collins Orthodontics, Rochester MN | ${this.props.title}`
          }
          title={
            this.props.metaTitle ||
            `${this.props.title}. Click the link to find out more!`
          }
        />
        {this.props.title && (
          <h1 id="page-title" css={titleStyle}>
            {this.props.title.toUpperCase()}
          </h1>
        )}
      </div>
    )
  }
}

export default PageTitle
